import gql from "graphql-tag";

export const FieldConfig = gql`
	fragment FieldConfig on FieldConfig {
		id
		key
		label
		helper
		type
		public
		options {
			nodes {
				id
				display
				value
				enabled
			}
		}
	}
`;

export const CreateField = gql`
	mutation CreateField($input: CreateFieldInput!) {
		createField(input: $input) {
			field {
				id
				value
			}
		}
	}
`;

export const UpdateField = gql`
	mutation UpdateField($input: UpdateFieldInput!) {
		updateField(input: $input) {
			field {
				id
				value
			}
		}
	}
`;
